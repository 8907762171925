import React from "react";
import Animation from "../Assets/Animation.json";
import Lottie from "lottie-react";

function UnderMaintenance() {
  return (
    <div className="animate">
      <Lottie animationData={Animation} />
      <p>
        &#169; 2024
        <a href="https://svsrco.com" target="_blank" rel="noreferrer">
          &nbsp;SVSR Communications
        </a>
        . All rights reserved.
      </p>
    </div>
  );
}

export default UnderMaintenance;
