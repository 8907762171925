import UnderMaintenance from "./Components/UnderMaintenance";

function App() {
  return (
    <>
      <UnderMaintenance />
    </>
  );
}

export default App;
